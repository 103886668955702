import { config } from '../config/config';

const { dataReceived } = require('./EnquiryHandler');
const {
  setIntentName,
  getIntentName,
  getButtonName,
  setButtonName,
  setIntentTicket,
  getIntentTicket,
  setCurrentFlow,
  getCurrentFlow,
  getNumber,
  setNumber,
  getIntentValue,
  setIntentValue,
  setIsOTPValidated,
  getAWBValue,
  setIsOtpSent,
} = require('../SessionStorage');
const {
  fetchHeaders,
  createPayload,
  createOTPPayload,
  createIntentPayload,
  createStatusPayload,
  createRequestPayload,
  createTicketPayload,
} = require('../utils/payloads');

const fetchRecordsPrompt = config.prompt_of_fetch_records;
const fetchRecordsOtpPrompt = config.prompt_of_fetch_records_otp;
const statusButtons = config.status_buttons;
const ChatBotApiUrl = process.env.REACT_APP_CHATBOT_API;
export const fetchHomeButtonsOptions = async () => {
  try {
    const payload = await createPayload('Home', fetchRecordsPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (result && result.response && result.response.length > 0) {
      if (result.response[0].buttons) {
        return result.response[0].buttons;
      } else if (result.response[0].text) {
        return [];
      }
    }
    return [];
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
};

export async function fetchOptionsOfInput(input, flag) {
  try {
    if (flag) {
      setButtonName(input);
      fetchIntentOfInput(input, false);
    }
    const payload = await createPayload(input, fetchRecordsPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (result && result.response && result.response.length > 0) {
      if (result.response[0].buttons) {
        return result.response[0].buttons;
      } else if (result.response[0].text) {
        return [];
      }
    }
    return [];
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
}

export const sendOTPFunction = async (mobileNo) => {
  try {
    setNumber(mobileNo);
    const payload = await createPayload(mobileNo, fetchRecordsOtpPrompt);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    setIsOtpSent(true);
    // console.log('response- -->>', response);
    return;
  } catch (error) {
    console.error('Error sending webhook data:', error);
    return [];
  }
};

export const validateOTPFunction = async (otp) => {
  try {
    const sentNumber = getNumber();
    const payload = await createOTPPayload(otp, sentNumber);
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();

    if (result && result.response && result.response.length > 0) {
      let msg = result.response[0].text;
      setIsOTPValidated(false);
      if (msg == 'OTP verified successfully') {
        setIsOTPValidated(true);
        setIsOtpSent(false);
        return 'OTP-SUCCESS';
      } else if (msg == 'OTP has expired') {
        return 'OTP-EXPIRED';
      } else if (msg == 'Invalid OTP') {
        return 'OTP-FAILED';
      } else {
        return 'OTP-NO';
      }
    }
    return 'OTP-NO';
  } catch (error) {
    return 'OTP-NO';
  }
};

export const fetchIntentsFromStorage = async (shippingId) => {
  let data = {
    intent_name: getIntentName() ? getIntentName() : 'order-status',
    intent_ticket: getIntentTicket()
      ? getIntentTicket()
      : 'order-status-ticket',
    type: getCurrentFlow() ? getCurrentFlow() : 'default',
  };
  let mobileNo = shippingId
    ? shippingId
    : getAWBValue()
    ? getAWBValue()
    : getNumber();
  let resp = await fetchIntentsResponse(data, mobileNo);
  return resp;
};

export const fetchIntentsResponse = async (data, shippingId) => {
  try {
    setIntentName(data['intent_name']);
    setCurrentFlow(data['type']);
    if (data['intent_name']) {
      setIntentTicket(data['intent_ticket']);
      const payload = await createStatusPayload(
        shippingId,
        data['intent_name']
      );
      const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let result = await response.json();
      // console.log('result -->>', result);
      return await dataReceived(result);
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error sending fetchIntentOfInput:', error);
    return [];
  }
};

export const fetchIntentOfInput = async (shippingId, flag) => {
  try {
    shippingId = getAWBValue();
    const currentButtonValue = getButtonName();
    const currentFlow = getCurrentFlow();
    if (currentButtonValue) {
      const payload = await createIntentPayload(
        currentButtonValue,
        'fetch-intents'
      );
      const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let result = await response.json();
      if (result && result.response && result.response.length > 0) {
        if (result.response[0].intents) {
          let data = result.response[0].intents;
          if (flag) return await fetchIntentsResponse(data, shippingId);
          setIntentName(data['intent_name']);
          setCurrentFlow(data['type']);
          setIntentTicket(data['intent_ticket']);
          return [];
        } else if (statusButtons.includes(currentFlow)) {
          return await fetchIntentsFromStorage(shippingId);
        }
        return [];
      }
      return [];
    } else {
      // console.log('intents data received', currentButtonValue);
      return await fetchIntentsFromStorage(shippingId);
    }
  } catch (error) {
    console.error('Error sending fetchIntentOfInput:', error);
    return [];
  }
};

export async function getFutureDeliveryDates(response) {
  try {
    let today = new Date();
    for (let i = 1; i <= 3; i++) {
      let date = new Date(today);
      date.setDate(today.getDate() + i);
      date = `${date.getDate()} ${date.getMonth() + 1} ${date.getFullYear()}`;
      response.push({ type: 'date', content: date });
    }
    return response;
  } catch (error) {
    console.log('error in getFutureDeliveryDates');
    return [];
  }
}

export const createUpdateTicket = async (updatedValue, ticket) => {
  try {
    let ticketPayload = await createTicketPayload(
      getAWBValue(),
      ticket,
      getIntentName(),
      updatedValue
    );
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    if (getIntentName() == 'update-delivery-date') {
      requestInsertionToDB('write', 'update', '', updatedValue);
    } else {
      requestInsertionToDB('write', 'update', updatedValue, '');
    }
    let ticketData = await dataReceived(result);
    return ticketData;
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

export const createStatusTicket = async (shipmentId, ticket) => {
  try {
    let ticketPayload = await createTicketPayload(
      shipmentId,
      ticket,
      'ticket_data',
      getIntentValue()
    );
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    requestInsertionToDB('read', 'status', '', '');
    return [];
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

async function updateIntentValues() {
  try {
    let currentFlow = getCurrentFlow();
    let flag = false;
    if (
      currentFlow == 'Wrong product has been picked by service representative.'
    ) {
      setIntentValue('56');
      flag = true;
    } else if (currentFlow == 'The product is not yet picked.') {
      setIntentValue('90');
      flag = true;
    } else if (
      currentFlow == 'It is about misbehavior by the service representative.'
    ) {
      flag = false;
      setIntentValue('21');
    } else if (
      currentFlow ==
      'I have been charged extra amount charged by the service representative.'
    ) {
      flag = false;
      setIntentValue('19');
    } else {
      flag = false;
    }
    return flag ? 'ticket_data' : 'complaint-raise';
  } catch (error) {
    console.log('error in updateIntentValues', error);
  }
}

export const createComplintTicket = async (shipmentId, ticket) => {
  try {
    const name_extra = await updateIntentValues();
    let ticketPayload = await createTicketPayload(
      shipmentId,
      ticket,
      name_extra,
      getIntentValue()
    );
    const response = await fetch(
      ChatBotApiUrl,
      await fetchHeaders(ticketPayload)
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    // console.log('respssssss', result.response)
    requestInsertionToDB('write', 'complaint', '', '');
    let ticketData = await dataReceived(result);
    return ticketData;
  } catch (error) {
    console.log('error in createStatusTicket', error);
    return [];
  }
};

export const requestInsertionToDB = async (
  operation,
  type,
  updatedNo,
  updatedDate
) => {
  try {
    const payload = await createRequestPayload(
      getNumber(),
      operation,
      getIntentTicket(),
      getAWBValue(),
      getCurrentFlow(),
      updatedNo,
      updatedDate,
      type
    );
    const response = await fetch(ChatBotApiUrl, await fetchHeaders(payload));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let result = await response.json();
    // console.log('result -------->>>', result)
  } catch (error) {
    console.log('error in requestInsertionToDB', error);
    return;
  }
};
