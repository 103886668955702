import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Keep application related configs in this file

// all the page urls of the website
export const PAGE_URL = {
  HOMEPAGE: '/',
  SERVICES_B2C: '/services/b2c-logistics-services',
  SERVICES_B2B: '/services/b2b-logistics-services',
  SERVICES_CBL: '/services/international-courier-services',
  SERVICES_TPL: '/services/third-party-logistics',
  SERVICES_PAGE: '/services/service',
  CAREER_PAGE: '/career',
  ABOUT_US: '/about-us',
  LIFE_AT_XB: '/about-us#life',
  NEWSPAGE: '/news',
  NEWSPAGE_ALL: '/news/all',
  NEWSPAGE_SINGLE: '/news/:newsId/:newsTitle',
  CONTACT_US: '/contact-us',
  MEDIA_BLOGS: '/blogs',
  MEDIA_BLOGS_ALL: '/blogs/:blogsName',
  MEDIA_BLOGS_SINGLE: '/blogs/:blogId/:blogTitle',
  PRIVACY_POLICY: '/policies',
  VENDOR_POLICY: '/policies/vendor-policies',
  CODE_OF_CONDUCT: '/policies/code-of-conduct',
  ANTI_CORRUPTION: '/policies/anti-corruption-policy',
  SHIPMENT_TRACKING: '/shipment/tracking',
  TERMS_OF_USE: '/policies/terms-of-use',
  TEST: '/test',
  OLD_WEBSITE_TRACKING_PAGE: '/track',
  PARTNER: '/delivery_partner',
};

export const PAGE_URL_REGEX = {
  SHIPMENT_TRACKING: /^\/shipment\/tracking\/[0-9]+$/i,
};

export const LANGUAGES = {
  en: 'English',
};

export const DEVICE_SIZES = {
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};

export const DEVICE_TYPE = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  LAPTOP: 'LAPTOP',
  DESKTOP: 'DESKTOP',
};

export const DEVICES = {
  sMobile: `(max-width: 480px)`,
  mobile: `(max-width: ${DEVICE_SIZES.tablet}px)`,
  tablet: `(min-width: ${DEVICE_SIZES.tablet}px) and (max-width:${DEVICE_SIZES.laptop}px)`,
  laptop: `(min-width: ${DEVICE_SIZES.laptop}px) and (max-width:${DEVICE_SIZES.desktop}px)`,
  desktop: `(min-width: ${DEVICE_SIZES.desktop}px)`,
};

export const EventBus = {
  UPDATE_META_INFORMATION: 'UPDATE_META_INFORMATION',
};
export const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
