import { getIntentName, setIntentValue } from '../SessionStorage';

const { fetchOptionsOfInput } = require('../utils/OptionsHandler');
const { config } = require('../config/config');

const additionalOptions = config.prompt_of_additional_options;

async function appendResponse(data) {
  try {
    var today = new Date();
    var date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;
    var type = '';
    for (let product = 0; product < data.length; product++) {
      if (
        data[product].businessType == 'forward' &&
        (getIntentName() == 'complaint-raise-wrong-picked' ||
          getIntentName() == 'complaint-raise-not-picked')
      ) {
        return `${data[product].poid} is not a return request. Please select appropriate option.`;
      } else if (
        data[product].businessType == 'reverse' &&
        (getIntentName() == 'complaint-raise-not-received' ||
          getIntentName() == 'update-delivery-date' ||
          getIntentName() == 'complaint-raise-charged-extra')
      ) {
        return `${data[product].poid} is not a delivery request. Please select appropriate option.`;
      } else if (data[product].businessType == 'forward') {
        if (
          data[product].orderPDD != 'None' &&
          data[product].orderPDD != null
        ) {
          data[product].orderPDD = data[product].orderPDD.split(' ')[0];
        }
        if (
          data[product].orderEDD != 'None' &&
          data[product].orderEDD != null
        ) {
          data[product].orderEDD = data[product].orderEDD.split(' ')[0];
        }
        if (data[product].order_type == 'PrePaid') {
          type = data[product].order_type + ' shipment';
        } else {
          type =
            data[product].order_type +
            ' shipment of Rs. ' +
            data[product].orderAmount;
        }
        if (data[product].status == 'Pending') {
          setIntentValue('108');
          return (
            data[product].poid +
            ' is a ' +
            type +
            ' ordered from ' +
            data[product].clientName +
            '. We will be able to confirm its estimated date of delivery once it is picked from the vendor/warehouse. I request you to come back after some time.'
          );
        } else if (
          (data[product].orderEDD == null ||
            data[product].orderEDD == 'None') &&
          (data[product].orderPDD == null || data[product].orderPDD == 'None')
        ) {
          setIntentValue('105');
          if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. Currently, it is in transit and has not reached its destination yet. We will try to deliver it ASAP.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. It is out for delivery today. Our service representative will contact you shortly.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO'
          ) {
            if (data[product].totalAttemptCount >= 1) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan'
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. Currently it is in transit and has not reached destination. We will try to deliver it as soon as possible. You will be notified by SMS and a phone call once it is out for delivery.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].totalAttemptCount >= 1
          ) {
            if (
              data[product].reattemptDate != 'None' &&
              data[product].reattemptDate != null
            ) {
              let reattemptDate = data[product].reattemptDate.split(' ')[0];
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                reattemptDate +
                '.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null ||
              data[product].totalAttemptCount == 'None' ||
              data[product].totalAttemptCount == 'NULL')
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. It has reached destination. We will try to delivered it as soon as possible. You will be notified by SMS and a phone call once its out for delivery.'
            );
          } else if (
            data[product].status == 'Delivered' &&
            data[product].orderStatus == 'Delivered'
          ) {
            setIntentValue('100');
            let deliveryDate = data[product].deliveryDateTime;
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We have successfully delivered it to ' +
              data[product].receiverName +
              ' on ' +
              deliveryDate +
              '.'
            );
          } else if (data[product].status == 'RTO') {
            setIntentValue('2');
            if (data[product].orderStatus == 'WHHandover') {
              if (data[product].totalAttemptCount > 1) {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              }
            } else {
              if (data[product].totalAttemptCount > 1) {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              }
            }
          } else if (data[product].status == 'Lost') {
            setIntentValue('4');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been lost in transit. Please contact client in case you have any queries.'
            );
          } else if (data[product].status == 'STD') {
            setIntentValue('3');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been damaged in transit. Please contact client in case you have any queries.'
            );
          }
        } else {
          setIntentValue('106');
          if (
            data[product].orderPDD == 'None' ||
            data[product].orderPDD == null
          ) {
            data[product].orderPDD = data[product].orderEDD;
          }

          if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. Currently, it is in transit and has not reached its destination yet. We will try to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].ndrCode == 'Out for Delivery'
          ) {
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. It is out for delivery today. Our service representative will contact you shortly.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].notificationType == 'RTO'
          ) {
            setIntentValue('2');
            if (data[product].totalAttemptCount >= 1) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan' &&
            data[product].orderPDD >= dateTime
          ) {
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Currently it is in transit and has not reached destination. We will try to deliver it ASAP.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Currently it is in transit and has not reached destination. We will try to deliver it by ' +
                data[product].orderPDD +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus != 'InScan' &&
            data[product].orderPDD < dateTime
          ) {
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We apologise! Currently it is in transit and has not reached destination. We will try to deliver it ASAP.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. We apologise! Currently it is in transit and has not reached destination. We will try to deliver it by ' +
                data[product].orderPDD +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD >= dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            if (data[product].orderPDD < dateTime) {
              var verb = 'was';
            }
            if (data[product].orderPDD >= dateTime) {
              var verb = 'is';
            }
            if (
              data[product].reattemptDate != 'None' &&
              data[product].reattemptDate != null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                data[product].reattemptDate +
                '.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('1');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. Its estimated date of delivery is ' +
              data[product].orderPDD +
              '. It has reached its destination. We will try to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD < dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            setIntentValue('1');
            if (data[product].orderPDD < dateTime) {
              var verb = 'was';
            }
            if (data[product].orderPDD >= dateTime) {
              var verb = 'is';
            }
            if (
              data[product].reattemptDate == 'None' ||
              data[product].reattemptDate == null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery ' +
                verb +
                ' ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it on ' +
                data[product].reattemptDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD >= dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('1');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. Its estimated date of delivery is ' +
              data[product].orderPDD +
              '. It has reached its destination. We will try to deliver it by ' +
              data[product].orderPDD +
              '.'
            );
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD < dateTime &&
            data[product].totalAttemptCount >= 1
          ) {
            setIntentValue('1');
            if (
              data[product].reattemptDate == 'None' ||
              data[product].reattemptDate == null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We tried but could not deliver it because ' +
                data[product].ndrCode +
                ' on ' +
                data[product].lastNDRDate +
                '. We will reattempt to deliver it by ' +
                data[product].reattemptDate +
                '.'
              );
            }
          } else if (
            data[product].status == 'Shipped' &&
            data[product].orderStatus == 'InScan' &&
            data[product].orderPDD < dateTime &&
            (data[product].totalAttemptCount == 0 ||
              data[product].totalAttemptCount == null)
          ) {
            setIntentValue('1');
            if (
              data[product].orderEDD == 'None' ||
              data[product].orderEDD == null
            ) {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We apologise. We will try to deliver it ASAP.'
              );
            } else {
              return (
                data[product].poid +
                ' is a ' +
                type +
                ' ordered from ' +
                data[product].clientName +
                '. Its estimated date of delivery was ' +
                data[product].orderPDD +
                '. We apologise. We will try to deliver it by ' +
                data[product].orderEDD +
                '.'
              );
            }
          } else if (
            data[product].status == 'Delivered' &&
            data[product].orderStatus == 'Delivered'
          ) {
            setIntentValue('100');
            let deliveryDate = data[product].deliveryDateTime;
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We have successfully delivered it to ' +
              data[product].receiverName +
              ' on ' +
              deliveryDate +
              '.'
            );
          } else if (data[product].status == 'RTO') {
            if (data[product].orderStatus == 'WHHandover') {
              setIntentValue('107');
              if (data[product].totalAttemptCount > 1) {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. It has been successfully returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              }
            } else {
              setIntentValue('104');
              if (data[product].totalAttemptCount > 1) {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. We tried but could not deliver it because ' +
                  data[product].ndrCode +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              } else {
                return (
                  data[product].poid +
                  ' is a ' +
                  type +
                  ' ordered from ' +
                  data[product].clientName +
                  '. It will be returned to its origin as per instructions from client. Please contact them in case you have any queries.'
                );
              }
            }
          } else if (data[product].status == 'Lost') {
            setIntentValue('4');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been lost in transit. Please contact client in case you have any queries.'
            );
          } else if (data[product].status == 'STD') {
            setIntentValue('3');
            return (
              data[product].poid +
              ' is a ' +
              type +
              ' ordered from ' +
              data[product].clientName +
              '. We apologise. We are unable to deliver it because it has been damaged in transit. Please contact client in case you have any queries.'
            );
          } else {
            setIntentValue('99');
            return (
              'Sorry! I could not find the status of this shipment ID. Please contact customer care on ' +
              data[product].ccNumber +
              '.'
            );
          }
        }
      } else if (data[product].businessType == 'reverse') {
        if (
          data[product].status == 'Pending' &&
          data[product].ndrCode == 'Out for PickUp'
        ) {
          setIntentValue('108');
          return (
            'Pickup request of Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. Our service representative is out for pickup today. He will contact you shortly.'
          );
        } else if (
          data[product].status == 'Pending' &&
          data[product].orderStatus == 'PickupNotDone'
        ) {
          setIntentValue('109');
          if (data[product].totalAttemptCount >= 1) {
            if (
              data[product].reattemptDate == null ||
              data[product].reattemptDate == 'None'
            ) {
              return (
                'Pickup request of Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          } else {
            if (
              data[product].manifestDataReceived == null ||
              data[product].manifestDataReceived == 'None'
            ) {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              return (
                'Pickup request of Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitmanifestDate = await splitDate(
                data[product].manifestDataReceived
              );
              let eligibleDate = await getEligiblityDate(
                splitmanifestDate,
                'Manifest'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          }
        } else if (
          data[product].status == 'Pending' &&
          data[product].ndrCode == 'Pick up done'
        ) {
          setIntentValue('111');
          return (
            'Pickup request of Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. We have already picked up this product and it will be delivered to its origin very soon.'
          );
        } else if (data[product].status == 'Pending') {
          setIntentValue('108');
          if (data[product].totalAttemptCount >= 1) {
            if (
              data[product].reattemptDate == null ||
              data[product].reattemptDate == 'None'
            ) {
              return (
                'Pickup request of Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We tried but could not pick the product because ' +
                  data[product].lastNDRUserProcess +
                  ' on ' +
                  data[product].lastNDRDate +
                  '. We will reattempt to pick it on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          } else {
            if (
              data[product].manifestDataReceived == null ||
              data[product].manifestDataReceived == 'None'
            ) {
              let splitReattemptDate = await splitDate(
                data[product].reattemptDate
              );
              let eligibleDate = await getEligiblityDate(
                splitReattemptDate,
                'Reattempt'
              );
              return (
                'Pickup request of Shipment ID ' +
                data[product].poid +
                ' was initiated by ' +
                data[product].clientName +
                ' on ' +
                data[product].manifestDataReceived +
                '. We tried but could not pick the product because ' +
                data[product].lastNDRUserProcess +
                ' on ' +
                data[product].lastNDRDate +
                '. We will try to pick it as soon as possible. You will be notified by SMS and a phone call once it is out for pick up.'
              );
            } else {
              let splitmanifestDate = await splitDate(
                data[product].manifestDataReceived
              );
              let eligibleDate = await getEligiblityDate(
                splitmanifestDate,
                'Manifest'
              );
              if (eligibleDate == 'ASAP') {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product ' +
                  eligibleDate +
                  '.'
                );
              } else {
                return (
                  'Pickup request of Shipment ID ' +
                  data[product].poid +
                  ' was initiated by ' +
                  data[product].clientName +
                  ' on ' +
                  data[product].manifestDataReceived +
                  '. We will try to pick up the product on ' +
                  eligibleDate +
                  '.'
                );
              }
            }
          }
        } else if (data[product].orderStatus == 'RPCancel') {
          setIntentValue('110');
          if (data[product].totalAttemptCount >= 1) {
            return (
              'Pickup request of Shipment ID ' +
              data[product].poid +
              ' was initiated by ' +
              data[product].clientName +
              ' on ' +
              data[product].manifestDataReceived +
              '. We tried but could not pick the product because ' +
              data[product].lastNDRUserProcess +
              ' on ' +
              data[product].lastNDRDate +
              '. The pickup request has been cancelled as per instructions by the client. Please contact them in case you have any queries.'
            );
          } else {
            return (
              'Pickup request of Shipment ID ' +
              data[product].poid +
              ' was initiated by ' +
              data[product].clientName +
              ' on ' +
              data[product].manifestDataReceived +
              '. The pickup request has been cancelled as per instructions by the client. Please contact them in case you have any queries.'
            );
          }
        } else if (data[product].orderStatus == 'PickupDone') {
          setIntentValue('111');
          return (
            'Pickup request of Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. We have already picked up this product and it will be delivered to its origin very soon.'
          );
        } else if (data[product].status == 'DTO') {
          setIntentValue('113');
          return (
            'Pickup request of Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. We have already picked up this product on ' +
            data[product].originHubInScan +
            ' and it will be delivered to its origin on ' +
            data[product].deliveryDateTime +
            '.'
          );
        } else if (data[product].status == 'Shipped') {
          setIntentValue('111');
          return (
            'Pickup request of Shipment ID ' +
            data[product].poid +
            ' was initiated by ' +
            data[product].clientName +
            ' on ' +
            data[product].manifestDataReceived +
            '. We have already picked up this product on ' +
            data[product].originHubInScan +
            ' and it will be delivered to its origin very soon.'
          );
        } else {
          setIntentValue('99');
          return (
            'Sorry! I could not find the status of this shipment ID. Please contact customer care on ' +
            data[product].ccNumber +
            '.'
          );
        }
      }
    }
  } catch (error) {
    return 'No data found';
  }
}

async function eligibleDateFormat(date) {
  try {
    var day = date;
    var dt =
      day.getDate() + '-' + (day.getMonth() + 1) + '-' + day.getFullYear();
    return dt;
  } catch (error) {
    console.log('Error in function eligibleDateFormat', error);
  }
}

async function setDateHMS(h, m, s, date) {
  date.setHours(h);
  date.setMinutes(m);
  date.setSeconds(s);
  return date;
}

async function getEligiblityDate(date, flag) {
  try {
    if (flag == 'Manifest') {
      let date1 = new Date();
      date1.setDate(date1.getDate(date1) - 1);
      date1 = await setDateHMS(13, 20, 0, date1);
      let date2 = new Date();
      date2 = await setDateHMS(7, 49, 59, date2);
      let date3 = new Date();
      date3 = await setDateHMS(7, 50, 0, date3);
      let date4 = new Date();
      date4 = await setDateHMS(10, 29, 59, date4);
      let date5 = new Date();
      date5 = await setDateHMS(10, 30, 0, date5);
      let date6 = new Date();
      date6 = await setDateHMS(13, 19, 59, date6);
      if (date < date1) {
        let eligibleDate = 'ASAP';
        return eligibleDate;
      } else if (date > new Date()) {
        let eligibleDate = await eligibleDateFormat(date);
        return eligibleDate;
      } else if (date > date1 && date < date2) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date3 && date < date4) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date5 && date < date6) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date6) {
        let eligibleDate = new Date();
        eligibleDate.setDate(eligibleDate.getDate() + 1);
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      }
    } else {
      let date1 = new Date();
      date1.setDate(date1.getDate(date1) - 1);
      date1 = await setDateHMS(16, 0, 0, date1);
      let date2 = new Date();
      date2 = await setDateHMS(7, 49, 59, date2);
      let date3 = new Date();
      date3 = await setDateHMS(7, 50, 0, date3);
      let date4 = new Date();
      date4 = await setDateHMS(10, 29, 59, date4);
      let date5 = new Date();
      date5 = await setDateHMS(10, 30, 0, date5);
      let date6 = new Date();
      date6 = await setDateHMS(13, 19, 59, date6);
      let date7 = new Date();
      date7 = await setDateHMS(13, 20, 0, date7);
      let date8 = new Date();
      date8 = await setDateHMS(15, 59, 59, date8);
      if (date < date1) {
        let eligibleDate = 'ASAP';
        return eligibleDate;
      } else if (date > new Date()) {
        let eligibleDate = await eligibleDateFormat(date);
        return eligibleDate;
      } else if (date > date1 && date < date2) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date3 && date < date4) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date5 && date < date6) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      } else if (date > date7 && date < date8) {
        let eligibleDate = new Date();
        eligibleDate = await eligibleDateFormat(eligibleDate);
        return eligibleDate;
      }
    }
  } catch (error) {
    console.log('Error in function getEligiblityDate', error);
  }
}

async function splitDate(date) {
  try {
    let day = date;
    let dateSplit = day.split(' ');
    let dd = dateSplit[0].split('-', 1)[0];
    let mm = dateSplit[0].split('-', 2)[1];
    mm = mm - 1;
    let yy = dateSplit[0].split('-')[2];

    let hh = dateSplit[1].split(':', 1)[0];
    let mi = dateSplit[1].split(':', 2)[1];
    let ss = dateSplit[1].split(':')[2];

    let finalDate = new Date(yy, mm, dd, hh, mi, ss);
    return finalDate;
  } catch (error) {
    console.log('Error in function splitDate', error);
  }
}

function returnTime() {
  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

async function appendordercard_list(data) {
  try {
    let responseArray = [];
    if (data && data.length == 0)
      return 'Sorry! I could not find any shipments on your registered mobile number.\n Please contact our Customer Care on 020-67664200';
    let shipmentsType =
      data && data.length > 1 ? 'Multiple Shipments' : 'Single Shipment';
    const respArr = await fetchOptionsOfInput(shipmentsType, false);
    if (respArr.length) {
      for (let obj of respArr) {
        let Obj = {
          type: 'text',
          content: obj.content,
          timestamp: returnTime(),
        };
        responseArray.push(Obj);
      }
    }
    for (let product = 0; product < data.length; product++) {
      let obj = {
        type: 'button',
        content: `Shipment ID : ${data[product].shipment_id}
                            Status : ${data[product].shipment_status}`,
      };
      responseArray.push(obj);
    }
    return responseArray;
  } catch (error) {
    return [];
  }
}

export async function dataReceived(data) {
  try {
    for (let element = 0; element < data.response.length; element++) {
      if (element == 0) {
        if (element == data.response.length - 1) {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        } else {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        }
      } else if (element > 0) {
        if (element == data.response.length - 1) {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            let msg = await appendResponse(data.response[element]['orderCard']);
            return msg;
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        } else {
          if (data.response[element]['type'] == 'text') {
            return data.response[element]['text'];
          } else if (data.response[element]['type'] == 'orderCard') {
            return await appendResponse(data.response[element]['orderCard']);
          } else if (data.response[element]['type'] == 'orderCard_list') {
            let resp = await appendordercard_list(
              data.response[element]['orderCard_list']
            );
            return resp;
          } else if (data.response[element]['type'] == 'closemenu') {
            let resp = await fetchOptionsOfInput(additionalOptions, false);
            return resp;
          }
        }
      }
    }
  } catch (error) {
    console.log('error in function dataReceived', error);
    return 'No data found catch';
  }
}
