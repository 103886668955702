import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';

export const StyledChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  height: 320px;
  background-color: rgb(217, 225, 241);
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  /* Scrollbar for Firefox */
  scrollbar-color: #888 #f1f1f1;

  /* Webkit-based browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${DEVICES.mobile} {
    height: calc(100% - 110px);
    overflow-y: auto;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    padding-bottom: 20px;
  }

  @media ${DEVICES.tablet} {
    height: calc(100% - 100px);
    overflow-y: auto;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    padding-bottom: 20px;

    /* Webkit-based browsers (Chrome, Safari, Edge) */
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const StyledMessageBox = styled.div`
  padding: 15px;
  border-radius: 10px;
  margin: 15px;
  position: relative;
  display: inline-block;
  width: fit-content;
  max-width: 85%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: linear-gradient(
    135deg,
    #ffa673,
    #ffc27a
  ); /* Lighter gradient colors */
  color: #565756; /* Ensures text remains legible */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */

  /* Top-left image styles */
  .top-left-image {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 40px;
    height: auto;
    border-radius: 50%;
  }

  /* Default message text styles */
  .message-text {
    font-size: 14px;
    margin: 0;
    padding-left: 25px;
    white-space: pre-wrap;
  }

  /* User-specific message styles */
  &.user-message {
    background: linear-gradient(135deg, #ffc27a, #ffa673);
    margin-right: 10px;
    color: #565756;
    align-self: flex-end;
    float: right;
    clear: both;

    .user-text {
      padding-left: 0;
      padding-right: 20px;
    }

    svg {
      position: absolute;
      bottom: -2px;
      right: -4px;
      font-size: 30px;
      background-color: transparent;
      color: white;
    }
  }

  &.server-message {
    .server-message {
      justify-content: flex-start;
      margin-left: 10px;
      color: #565756;
      background: linear-gradient(135deg, #ffa673, #ffc27a);
    }
  }

  @media ${DEVICES.sMobile} {
    max-width: 90%;
  }

  @media ${DEVICES.mobile} {
    padding: 10px;
    margin: 10px;
  }
`;

export const StyledTopLeftImage = styled.img`
  position: absolute;
  top: -5px;
  left: -2px;
  width: 30px;
  height: 30px;
  height: auto;
  border-radius: 50%;
`;

export const StyledUserMessage = styled.div`
  justify-content: flex-end;
  background-color: #ff7e3c;
  margin-right: 10px;
  color: white;
  align-self: flex-end;
  float: right;
  clear: both;
`;

export const StyledUSerText = styled.span`
  padding: 15px;
  border-radius: 10px;
  margin: 15px;
  position: relative;
  display: inline-block;
  width: fit-content;
  max-width: 85%;
  overflow-wrap: break-word;
  background: linear-gradient(135deg, rgb(255, 166, 115), rgb(255, 194, 122));
  color: rgb(86, 87, 86);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
`;

export const StyledButtonContainer = styled.div`
  padding: 0 15px;

  .chat-button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(255, 166, 115);

    &:hover {
      box-shadow: 0px 2px 16px rgb(245, 130, 32);
    }
  }

  .default-button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: white;
    color: #3a3a3a;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    .button-icon {
      width: 25px !important;
      height: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: inherit;
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 10px;
    }

    &:hover {
      box-shadow: 0px 2px 16px #00000050;
    }
  }

  .button-text {
    font-size: 14px;
    color: #565756;
    text-align: left;
  }

  .centerAlignIcon {
    color: #fff;
    font-size: 18px;
    margin-top: 2px;
  }

  @media ${DEVICES.mobile} {
    max-width: fit-content;
  }

  @media ${DEVICES.sMobile} {
    .button-text {
      font-size: 12px;
    }
  }
`;

export const StyledInputPromptContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 350px;
  height: auto;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 10px;
  z-index: 1001;

  .chat-input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
    border-radius: 8px;
    background-color: transparent;

    &::placeholder {
      font-size: 14px;
      opacity: 1;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media ${DEVICES.sMobile} {
    width: 90%;
    border-radius: 0;
  }

  @media ${DEVICES.mobile} {
    width: 100%;
    border-radius: 0;
  }

  @media ${DEVICES.tablet} {
    width: 100%;
    border-radius: 0;
  }
`;

export const StyledSendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f58220;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 30%;
`;

export const StyledHomeButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  .input-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #f58220;
  }
`;

export const StyleBtnClassTimer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
  align-self: flex-end;

  .tickmark {
    margin-right: 7px;

    &[data-icon='X']::before {
      content: '✓';
      color: black;
    }
  }
`;
