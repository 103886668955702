export const getIntentName = () => sessionStorage.getItem('intent-name') || '';

export const setIntentName = (newName) => {
  sessionStorage.setItem('intent-name', newName);
};

export const getButtonName = () => sessionStorage.getItem('button-name') || '';

export const setButtonName = (newName) => {
  sessionStorage.setItem('button-name', newName);
};

export const getIntentTicket = () =>
  sessionStorage.getItem('intent-ticket') || '';

export const setIntentTicket = (newName) => {
  sessionStorage.setItem('intent-ticket', newName);
};

export const getCurrentFlow = () =>
  sessionStorage.getItem('current-flow') || '';

export const setCurrentFlow = (newName) => {
  sessionStorage.setItem('current-flow', newName);
};

export const getNumber = () => sessionStorage.getItem('mobileno') || '';

export const setNumber = (newNo) => {
  sessionStorage.setItem('mobileno', newNo);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
  // console.log("Session storage has been cleared.");
};

export const getIntentValue = () =>
  sessionStorage.getItem('intent-value') || '';

export const setIntentValue = (newName) => {
  sessionStorage.setItem('intent-value', newName);
};

export const getAWBValue = () => sessionStorage.getItem('awb') || '';

export const setAWBValue = (newName) => {
  sessionStorage.setItem('awb', newName);
};

export const getIsOTPValidated = () => sessionStorage.getItem('valid') || '';

export const setIsOTPValidated = (newName) => {
  sessionStorage.setItem('valid', newName);
};

export const clearSelectedKeys = (keys) => {
  keys.forEach((key) => {
    sessionStorage.removeItem(key);
  });
};

export const getIsOtpSent = () => sessionStorage.getItem('otpSent') || false;

export const setIsOtpSent = (newValue) => {
  sessionStorage.setItem('otpSent', newValue);
};
