import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { META_INFO, getPage } from '../../config/metaInfo';
import useOnRouteChange from '../../hooks/useOnRouteChange';
import {
  StyledContainer,
  StyledContent,
  StyledLayoutWrapper,
} from '../../styles/GlobalStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SignUp from '../SignUp/SignUp';
import { PAGE_URL } from '../../config/appConfig';
import { useDispatch } from 'react-redux';
import { signupActions } from '../../store/slices/signupSlice';
import { useTranslation } from '../../hooks/useTranslation';
import useAnalytics from '../../hooks/useAnalytics';
import { SHIP_NOW_URL } from '../../config/constants';
import Chatbot from '../ChatBot/ChatBot';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';

/**
 * Layout component is the root level component to host all routes/components
 * @param {object} props - props object
 * @param {string} props.id - unique identifier, if needed
 * @param {array} props.children - children components, if any
 *
 * @returns React.FC
 */
const Layout = ({ id, children }) => {
  const location = useLocation();
  const locationRef = useRef();
  const [metaInfo, setMetaInfo] = useState({});
  const dispatch = useDispatch();
  const t = useTranslation();
  const { trackEvent } = useAnalytics();
  const featureFlag = useFeatureFlags();

  // add page meta information on route change
  useOnRouteChange(() => {
    if (locationRef.current !== location.pathname) {
      let page = location.pathname.toLowerCase();
      if (page.match(/(.+)\/(\d+)\/(.+)/)) {
        page = page.split(/\/(\d+)\//)[0];
      }
      if (page.match(/\/blogs\/(\w+)/)) {
        page = PAGE_URL.MEDIA_BLOGS;
      }
      page = getPage(page);
      locationRef.current = location.pathname;
      setMetaInfo({
        title: META_INFO[page]?.title,
        description: META_INFO[page]?.description,
        canonical: META_INFO[page]?.canonical,
      });
    }
  });

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel='canonical' href={metaInfo?.canonical} />
          <title>{metaInfo?.title}</title>
          <meta name='description' content={metaInfo?.description} />
        </Helmet>
        <StyledLayoutWrapper data-testid={`${id}-layout`}>
          <StyledContainer data-testid={`${id}-container`}>
            <SignUp />
            <div className='stickyBtn'>
              <Link to={SHIP_NOW_URL} target='_blank'>
                <button onClick={() => trackEvent('ShipNowEvent', {})}>
                  {t('app.SHIP_NOW')}
                </button>
              </Link>
            </div>
            <div className='enquiryBtn'>
              <button onClick={() => dispatch(signupActions.showSignupModal())}>
                {t('app.ENQUIRE_NOW')}
              </button>
            </div>
            <Header />
            <StyledContent>{children}</StyledContent>
            <Footer />
            {[
              PAGE_URL.HOMEPAGE,
              PAGE_URL.CONTACT_US,
              PAGE_URL.SHIPMENT_TRACKING,
            ].includes(location.pathname) &&
              featureFlag.SHOW_CHAT_BOT && <Chatbot />}
          </StyledContainer>
        </StyledLayoutWrapper>
      </HelmetProvider>
    </>
  );
};

export default Layout;
