import { PAGE_URL } from './appConfig';

export const getPage = (url) => {
  const pageURL = [
    PAGE_URL.HOMEPAGE,
    PAGE_URL.SERVICES_B2C,
    PAGE_URL.SERVICES_B2B,
    PAGE_URL.SERVICES_CBL,
    PAGE_URL.SERVICES_TPL,
    PAGE_URL.ABOUT_US,
    PAGE_URL.CAREER_PAGE,
    PAGE_URL.CONTACT_US,
    PAGE_URL.MEDIA_BLOGS,
    PAGE_URL.MEDIA_BLOGS_ALL,
    PAGE_URL.MEDIA_BLOGS_SINGLE,
    PAGE_URL.NEWSPAGE,
    PAGE_URL.NEWSPAGE_ALL,
    PAGE_URL.NEWSPAGE_SINGLE,
    PAGE_URL.SHIPMENT_TRACKING,
  ];
  return pageURL.indexOf(url) !== -1 ? url : '/';
};

export const META_INFO = {
  [PAGE_URL.HOMEPAGE]: {
    title: "Trusted Courier Service's | Domestic & International Delivery",
    description:
      'XpressBees offers reliable courier services for B2C, B2B, and cross-border businesses',
    canonical: 'https://www.xpressbees.com',
  },
  [PAGE_URL.SERVICES_B2C]: {
    title: 'Ecommerce Logistics Serevices| Fast & Cost-Effective',
    description:
      'Xpressbees offers Ecommerce logistics services with fast order fulfillment, seamless last-mile delivery, and real-time tracking.',
    canonical: 'https://www.xpressbees.com/services/b2c-logistics-services',
  },
  [PAGE_URL.SERVICES_B2B]: {
    title: 'Trusted B2B Logistics Services | Fast, Secure & Cost-Effective',
    description:
      'XpressBees offers efficient B2B logistics with secure, timely deliveries, real-time tracking, and comprehensive end-to-end supply chain solutions',
    canonical: 'https://www.xpressbees.com/services/b2b-logistics-services',
  },
  [PAGE_URL.SERVICES_CBL]: {
    title:
      'Reliable International Courier Services | Fast & Affordable Shipping Worldwide',
    description:
      'XpressBees delivers your international shipments door-to-door within 6-10 working days. Rely on us for fast, secure, and efficient logistics',
    canonical:
      'https://www.xpressbees.com/services/international-courier-services',
  },
  [PAGE_URL.SERVICES_TPL]: {
    title: 'Expert 3PL Services | Streamlined Shipping & Warehousing Solutions',
    description:
      'XpressBees third-party logistics services for streamlined operations, real-time tracking, and customised solutions for optimal inventory management',
    canonical: 'https://www.xpressbees.com/services/third-party-logistics',
  },
  [PAGE_URL.SHIPMENT_TRACKING]: {
    title: 'Track Your Shipment | XpressBees Tracking',
    description:
      'Track your XpressBees shipment effortlessly. Real-time updates for local, national, and international courier. Experience Seamless tracking for efficient logistics.',
    canonical: 'https://www.xpressbees.com/shipment/tracking',
  },
  [PAGE_URL.MEDIA_BLOGS]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/blogs',
  },
  [PAGE_URL.MEDIA_BLOGS_SINGLE]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/blogs',
  },
  [PAGE_URL.MEDIA_BLOGS_ALL]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/blogs/all',
  },
  [PAGE_URL.NEWSPAGE_ALL]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/news/all',
  },
  [PAGE_URL.NEWSPAGE]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/news',
  },
  [PAGE_URL.NEWSPAGE_SINGLE]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/news',
  },
  [PAGE_URL.ABOUT_US]: {
    title: 'XpressBees: Your Trusted Partner for Seamless Logistics Solutions',
    description:
      "Explore XpressBees' journey– a leader in logistics.From commitment to excellence to redefining the sector, join us as we redefine the logistics experience for businesses.",
    canonical: 'https://www.xpressbees.com/about-us',
  },
  [PAGE_URL.CAREER_PAGE]: {
    title: 'Join XpressBees - Explore Career Opportunities in Logistics',
    description:
      'Unlock your potential with XpressBees Careers. Explore diverse opportunities in logistics. Join a team valuing innovation, growth, and commitment to excellence.',
    canonical: 'https://www.xpressbees.com/career',
  },
  [PAGE_URL.CONTACT_US]: {
    title: 'Contact XpressBees - Reach Out for Reliable Logistics Solutions',
    description:
      "Contact XpressBees for all your logistics needs: Inquiries, feedback, or partnerships—we're ready to assist. Experience seamless communication and reliable solutions.",
    canonical: 'https://www.xpressbees.com/contact-us',
  },
};
