import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';

// Styled Widget Message
export const StyledWidgetMsg = styled.div`
  z-index: 1;
  position: relative;

  .launcher {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 -7px -20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    z-index: 1;
    width: 88px;
    height: 97px;

    .botwidget {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
    }

    .botwidgetnotification {
      display: block;
      margin-left: -62px;
      margin-top: -35px;
      top: 26px;
      position: absolute;
      right: 9px;
      background-color: #f58220;
      color: #000;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      border: 1px solid #f58220;
    }
  }

  @media ${DEVICES.tablet} {
    .launcher {
      margin: 0;
      z-index: 120;
    }
  }
`;

// Styled Widget Notification
export const StyledWidgetNotification = styled.div`
  position: fixed;
  display: block;
  max-width: 225px;
  z-index: 120;
  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-fill-mode: forwards;
  right: 20px;
  bottom: 85px;
  font-size: 20px;
  font-weight: 600;
  color: #000;

  .M16_42 {
    font-size: 16px;
    color: #323232;
  }

  .widgettxt {
    max-width: 200px;
    padding: 10px 15px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    background: #fff;
    border: 0 solid;
    border-radius: 8px;
    font-size: 14px;
    animation-duration: 0.5s;
    animation-name: slide-in;
    animation-fill-mode: forwards;
    display: block;
  }

  .widgetdesc {
    font-size: 16px;
    color: #424242;
  }

  @media ${DEVICES.sMobile} {
    max-width: calc(100% - 20px);

    .widgettxt {
      display: none;
    }
  }
`;

// Styled Widget Popup Image
export const StyledWidgetPopImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */

  &:hover {
    transform: scale(1.1); /* Slightly enlarge the image */
    box-shadow: 0 0 15px #f58220; /* Intensify shadow */
    color: #f58220; /* Optional: Add hover color (if applicable) */
  }

  @media ${DEVICES.sMobile} {
    width: 50px;
    height: 50px;
  }

  @media ${DEVICES.tablet} {
    margin-left: 0;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    width: 72px;
    height: 72px;
  }
`;

// Styled Chatbot Container
export const StyledChatbotContainer = styled.div`
  width: 350px;
  height: 430px;
  position: fixed;
  bottom: 20px;
  right: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: whitesmoke;
  font-family: 'Roboto', sans-serif !important;
  z-index: 1000;

  @media ${DEVICES.sMobile}, ${DEVICES.mobile}, ${DEVICES.tablet} {
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
`;

// Styled Chatbot Header
export const StyledChatbotHeader = styled.div`
  background: linear-gradient(135deg, #f58220, #ffa673);
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 1;

  .header-text {
    display: flex;
    flex-direction: column;
  }

  .icons {
    display: flex;
    gap: 10px;

    .minimize-icon {
      cursor: pointer;
      font-size: 20px;
      padding: 5px;
      position: relative;
      top: -8px;
    }

    .close-icon {
      cursor: pointer;
      font-size: 25px;
      padding: 5px;
    }
  }

  h2 {
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  @media ${DEVICES.sMobile} {
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 10px;
    }
  }

  @media ${DEVICES.tablet} {
    border-radius: 0;
  }
`;

// Styled Popup Overlay
export const StyledPopUpOverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;

    p {
      margin: 4px 0;
      font-size: 14px;
      color: #000;
    }
  }

  .popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    font-size: 14px;

    .confirm-button {
      background-color: #f58220;
      height: 36px;
      padding: 8px 12px;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .cancel-button {
      background-color: white;
      color: #f58220;
      border: 1px solid #f58220;
      height: 36px;
      padding: 8px 12px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
`;
