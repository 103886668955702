import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import {
  FooterCol,
  FooterContainer,
  FooterCopyRight,
  FooterImg,
  FooterLogo,
  FooterRow,
  FooterTitle,
} from './Footer.styled';
import {
  SOCIAL_MEDIA_LINKS,
  getCompanyDetail,
  getLegalDetail,
  getServiceDetail,
} from './FooterConfig';
import { Link } from 'react-router-dom';

/**
 * Social Media function
 * This function renders social media icons with their respective links.
 *
 * @function
 * @example
 * renderSocialMediaData()
 */

const renderSocialMediaData = () => {
  return (
    <li>
      {SOCIAL_MEDIA_LINKS.map(({ href, alt, src, target }, index) => {
        return (
          <Link to={href} key={index} target={target}>
            <img alt={alt} src={src} className='smicon' loading='lazy' />
          </Link>
        );
      })}
    </li>
  );
};

/**
 * Service Detail Function
 * This Function renders the service details as a list of links.
 *
 * @Function
 * @param {Function} t - Translation function for translating text.
 * @example
 * renderServiceDetail(t) />
 */

const renderServiceDetail = ({ t }) => {
  const serviceDetail = getServiceDetail(t);
  return (
    <ul>
      {serviceDetail.map(({ href, detail }, index) => {
        return (
          <li key={index}>
            <Link to={href}>{detail}</Link>
          </li>
        );
      })}
    </ul>
  );
};

/**
 * Company Detail function
 * This Function renders the company details as a list of links.
 *
 * @Function
 * @param {Function} t - Translation function for translating text.
 * @example
 * renderCompanyDetail({t})/>
 */

const renderCompanyDetail = ({ t }) => {
  const companyDetail = getCompanyDetail(t);
  return (
    <ul>
      {companyDetail.map(({ href, detail, target }, index) => {
        return (
          <li key={index}>
            <Link to={href} target={target}>
              {detail}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

/**
 * Legal Details Function
 * This Function renders the legal details as a list of links.
 *
 * @Function
 * @param {Function} t - Translation function for translating text.
 * @example
 *
 */

const renderLegalDetail = ({ t }) => {
  const legalDetail = getLegalDetail(t);
  return (
    <ul>
      {legalDetail.map(({ href, detail }, index) => {
        return (
          <li key={index}>
            <Link to={href}>{detail}</Link>
          </li>
        );
      })}
    </ul>
  );
};

/**
 * Footer Component
 * React component for the Footer section of the website.
 * This component displays various information, links, and social media icons in the footer.
 *
 * @component
 * @example
 * import Footer from './Footer'; // Import the Footer component
 *<Footer/>
 */

const Footer = () => {
  const t = useTranslation();
  return (
    <FooterContainer>
      <FooterImg data-testid='footer-block'>
        <img
          src='/images/FooterImg.webp'
          className='w-100 FooterDesk'
          alt='Footer Image'
          loading='lazy'
        />
        <img
          src='/images/FooterImgMobile.webp'
          className='w-100 FooterMobile'
          alt='Footer Image'
          loading='lazy'
        />
      </FooterImg>
      <FooterLogo>
        <img
          src='/images/whiteLogo.webp'
          alt='XpressBees Logo'
          loading='lazy'
        />
      </FooterLogo>
      <FooterRow>
        <FooterCol>
          <FooterTitle>{t('app.FOOTER_TITLE')}</FooterTitle>
          <ul>
            <li>
              <img
                src='/images/socialIcon/phone.svg'
                alt='Phone'
                loading='lazy'
              />
              <Link to={t('app.LINK')}>{t('app.MOBILE_NO')}</Link>
            </li>
            <li>
              <img
                src='/images/socialIcon/mail.svg'
                alt='Mail'
                loading='lazy'
              />
              <Link to={t('app.MAIL')}> {t('app.MAIL_ID')}</Link>
            </li>
            {renderSocialMediaData()}
          </ul>
        </FooterCol>
        <FooterCol>
          <FooterTitle>{t('app.FOOTER_COMPANY')}</FooterTitle>
          {renderCompanyDetail({ t })}
        </FooterCol>
        <FooterCol>
          <FooterTitle>{t('app.SERVICE')}</FooterTitle>
          {renderServiceDetail({ t })}
        </FooterCol>
        <FooterCol>
          <FooterTitle>{t('app.FOOTER_LEGAL')}</FooterTitle>
          {renderLegalDetail({ t })}
        </FooterCol>
      </FooterRow>
      <FooterCopyRight>{t('app.FOOTER_INFO')}</FooterCopyRight>
    </FooterContainer>
  );
};
export default Footer;
