const { config } = require('../config/config');
const keycloakUrl = `${process.env.REACT_APP_AUTH2_API_URL}realms/${process.env.REACT_APP_AUTH2_REALM}/protocol/openid-connect/token`;

export const authenticateWithKeycloak = async () => {
  let client_id = process.env.REACT_APP_AUTH2_CLIENT_ID;
  let client_secret = process.env.REACT_APP_AUTH2_CLIENT_SECRET;
  let username = process.env.REACT_APP_CHATBOT_USERNAME;
  let password = process.env.REACT_APP_CHATBOT_PASSWORD;
  const params = new URLSearchParams({
    grant_type: config.grant_type,
    client_id: client_id,
    client_secret: client_secret,
    username: username,
    password: password,
  });
  try {
    const response = await fetch(keycloakUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    });

    if (!response.ok) throw new Error('Authentication failed');

    const data = await response.json();
    sessionStorage.setItem('accessToken', data.access_token);
    sessionStorage.setItem('tokenExpiry', Date.now() + 120000);
    return data.access_token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAccessToken = async () => {
  try {
    const tokenExpiry = sessionStorage.getItem('tokenExpiry') || '';
    if (Date.now() >= tokenExpiry) {
      console.log('expired');
      return await authenticateWithKeycloak();
    }
    // console.log('not expired')
    return sessionStorage.getItem('accessToken');
  } catch (error) {
    console.log('Error in function getAccessToken', error);
  }
};
