import React, { useEffect, useRef } from 'react';
import {
  FaQuestionCircle,
  FaRegUserCircle,
  FaPencilAlt,
  FaExclamationTriangle,
  FaHome,
  FaPaperPlane,
} from 'react-icons/fa';
import {
  StyleBtnClassTimer,
  StyledButtonContainer,
  StyledChatContainer,
  StyledHomeButton,
  StyledInputPromptContainer,
  StyledMessageBox,
  StyledSendButton,
  StyledTopLeftImage,
} from './ChatComponent.Styled';

const ChatComponent = ({
  selectedMessages,
  handleButtonClick,
  handleInputSubmit,
  showInput,
  placeholder,
  homeIcon,
  handleHomeButtonClick,
  handleKeyPress,
  setHomeIcon,
  userInput,
  setUserInput,
  setSelectedMessages,
  handleDateClick,
}) => {
  const endOfMessagesRef = useRef(null);
  const timeoutRef = useRef(null);
  const hasRunRef = useRef(false);

  const audio = useRef(new Audio('/images/chatbot/notification_alert.mp3'));
  const prevMessagesLength = useRef(selectedMessages.length);

  useEffect(() => {
    if (selectedMessages.length > prevMessagesLength.current) {
      const lastMessage = selectedMessages[selectedMessages.length - 1];
      if (lastMessage.type === 'server') {
        audio.current
          .play()
          .catch((error) => console.error('Error playing audio:', error));
      }
    }
    prevMessagesLength.current = selectedMessages.length;
  }, [selectedMessages]);

  const renderTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  async function returnTime() {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  useEffect(() => {
    if (endOfMessagesRef.current) {
      const scrollOptions = {
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      };

      endOfMessagesRef.current.scrollIntoView(scrollOptions);
      setTimeout(() => {
        const chatContainer =
          endOfMessagesRef.current.closest('.chat-container');
        if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
          chatContainer.scrollTop += 70;
        }
      }, 100);
    }
  }, [selectedMessages]);

  useEffect(() => {
    if (hasRunRef.current) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setSelectedMessages((prevMessages) => [
        ...prevMessages,
        {
          type: 'server',
          text: 'I have not received any response from you. Please respond appropriately. You can also click on Home icon to go back to main menu.',
          isType: 'text',
        },
      ]);
      setHomeIcon(true);
      hasRunRef.current = true;
    }, 60000);
    return () => clearTimeout(timeoutRef.current);
  }, [selectedMessages]);

  return (
    <StyledChatContainer>
      <StyledMessageBox>
        <StyledTopLeftImage src={'/images/chatbot/bcon_in.webp'} alt='icon' />
        <span className='message-text'>How may I assist you?</span>
      </StyledMessageBox>

      {selectedMessages.map((msg, index) => {
        if (msg.type === 'user') {
          return (
            <>
              <StyledMessageBox key={index} className='user-message'>
                <FaRegUserCircle />
                <span className='message-text user-text'>{msg.text}</span>
              </StyledMessageBox>
              <StyleBtnClassTimer>
                <i className='tickmark' data-icon='X'></i>
                {msg.timestamp}
              </StyleBtnClassTimer>
            </>
          );
        } else if (msg.type === 'server') {
          if (msg.isType === 'text') {
            return (
              <StyledMessageBox key={index} className='server-message'>
                <StyledTopLeftImage
                  src='/images/chatbot/bcon_in.webp'
                  alt='icon'
                />
                <span className='message-text'>{msg.text} </span>
              </StyledMessageBox>
            );
          } else if (msg.isType === 'button') {
            return (
              <StyledButtonContainer key={index}>
                <button
                  className='chat-button'
                  onClick={() => handleButtonClick(msg.text)}
                >
                  <span className='button-text'>
                    {renderTextWithLineBreaks(msg.text)}
                  </span>
                </button>
              </StyledButtonContainer>
            );
          } else if (msg.isType === 'date') {
            return (
              <StyledButtonContainer key={index}>
                <button
                  className='chat-button'
                  onClick={() => handleDateClick(msg.text)}
                >
                  <span className='button-text'>{msg.text}</span>
                </button>
              </StyledButtonContainer>
            );
          }
        } else if (Array.isArray(msg)) {
          return (
            <StyledButtonContainer key={index}>
              {msg.map((button, buttonIndex) => (
                <button
                  key={buttonIndex}
                  className='default-button'
                  onClick={() => handleButtonClick(button.content)}
                >
                  {buttonIndex == 0 && (
                    <span
                      className='button-icon'
                      style={{ backgroundColor: '#f58220' }}
                    >
                      <FaQuestionCircle className='centerAlignIcon' />
                    </span>
                  )}
                  {buttonIndex == 1 && (
                    <span
                      className='button-icon'
                      style={{ backgroundColor: '#af61cf' }}
                    >
                      <FaPencilAlt className='centerAlignIcon' />
                    </span>
                  )}
                  {buttonIndex == 2 && (
                    <span
                      className='button-icon'
                      style={{ backgroundColor: '#a3d063' }}
                    >
                      <FaExclamationTriangle className='centerAlignIcon' />
                    </span>
                  )}
                  <span className='button-text'>{button.content}</span>
                </button>
              ))}
            </StyledButtonContainer>
          );
        }
        returnTime();
      })}

      <div ref={endOfMessagesRef} />

      {showInput && (
        <StyledInputPromptContainer>
          <input
            type='text'
            inputMode='numeric'
            value={userInput}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*$/.test(inputValue)) {
                setHomeIcon(false);
                setUserInput(inputValue);
              }
            }}
            onKeyPress={handleKeyPress}
            className='chat-input'
            placeholder={placeholder}
          />
          {userInput && (
            <label data-role='none' className='sendbtndiv' tabIndex='0'>
              <StyledSendButton
                data-role='none'
                onClick={() => handleInputSubmit()}
              >
                <FaPaperPlane title='Send' />
              </StyledSendButton>
            </label>
          )}
          {homeIcon && (
            <StyledHomeButton onClick={() => handleHomeButtonClick()}>
              <span className='input-icon'>
                <FaHome title='Go back to main menu' />
              </span>
            </StyledHomeButton>
          )}
        </StyledInputPromptContainer>
      )}
    </StyledChatContainer>
  );
};

export default ChatComponent;
