// file for utility functions

/**
 * To check input string is valid JSON or not
 *
 * @param {string} input -input to check is valid JSON or not
 * @returns boolean - true, if valid else false
 */
export const isJson = (input) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Formats a date into a human-readable date string.
 *
 * @param {Date|string} date - The date to format. It can be a Date object or a valid date string.
 * @returns {string} A human-readable date string in the format "Month Day, Year".
 */
export const formatDate = (date) => {
  const tempDate = new Date(date);
  return `${tempDate.toLocaleDateString(undefined, {
    month: 'long',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

/**
 * Formats a date into a human-readable date string.
 *
 * @param {Date|string} date - The date to format. It can be a Date object or a valid date string.
 * @returns {string} A human-readable date string in the format "Month Day, Year".
 */
export const formatMonthDate = (date) => {
  const tempDate = new Date(date);
  return `${tempDate
    .toLocaleDateString(undefined, {
      month: 'short', // Abbreviated month
    })
    .toUpperCase()} ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

/**
 * Converts a string into a URL-friendly slug by converting to lowercase,
 * removing leading/trailing spaces, and replacing consecutive spaces with single hyphens.
 *
 * @param {string} inputString - The string to be converted into a slug
 * @returns {string} The formatted slug in lowercase with hyphens
 * @example
 * formatUrlSlug("Hello World");  // Returns "hello-world"
 * formatUrlSlug("  Multiple   Spaces  ");  // Returns "multiple-spaces"
 * formatUrlSlug("");  // Returns ""
 */
export const formatUrlSlug = (inputString = '') => {
  return inputString.toLowerCase().trim().replace(/\s+/g, '-'); // Replace spaces with hyphens
};

/**
 * Truncates a text string to a specified maximum length and adds an ellipsis.
 *
 * @param {string} text - The text to truncate
 * @param {number} maxLength - The maximum length of the text before truncation
 * @param {string} [ellipsis='...'] - The string to append to truncated text (default: '...')
 * @returns {string} The truncated text with ellipsis, or original text if no truncation needed
 *
 */
export const truncateText = (text, maxLength, ellipsis = '...') => {
  // Handle null, undefined, or non-string inputs
  if (!text) return '';

  // Convert to string in case of numbers or other types
  const stringText = String(text);

  // If text is shorter than maxLength, return original text
  if (stringText.length <= maxLength) {
    return stringText;
  }

  // Truncate text and add ellipsis
  return stringText.slice(0, maxLength) + ellipsis;
};

// Utility function for get data to safely access nested properties
export const get = (obj, path, defaultValue = undefined) => {
  const keys = path.split('.');
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
    obj
  );
};
