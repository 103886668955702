export const config = {
  // local
  // 'chatbotbackendapi': 'http://localhost:5017/webhook',

  // working stage credential
  // client_id: 'chat-bot-ui',
  // client_secret: 'Zq2Ppy9tz34aKORLhbBYYtfltXEYiXNC',
  // username: 'Testuserchatbot@xpressbees.com',
  // password: 'Test@123456789',
  // chatbotbackendapi: 'https://stage-chatbot.xbees.in/webhook',
  // auth_url: 'https://stage-auth.xbees.in/',
  // realms: 'xpressbees',
  grant_type: 'password',
  prompt_of_additional_options: 'Additional options',
  prompt_of_complaint_confirmation: 'Complaint confirmation',
  status_buttons: [
    'I want to enquire about my return request.',
    'I want to enquire about the estimated date of delivery of my shipment.',
  ],
  request_buttons: [
    'I want future date delivery.',
    'I want to update my alternate contact number.',
  ],
  complaint_buttons: [
    'I have not received my shipment yet.',
    'It is about misbehavior by the service representative.',
    'I have been charged extra amount charged by the service representative.',
    'Wrong product has been picked by service representative.',
    'The product is not yet picked.',
  ],
  prompt_of_otp: 'OTP',
  prompt_of_number: 'Number Prompt',
  prompt_of_fetch_records: 'fetch-records',
  prompt_of_fetch_records_otp: 'fetch-records-otp',
  prompt_of_invalid_number: 'Invalid Number',
  session_keys: [
    'awb',
    'button-name',
    'current-flow',
    'intent-name',
    'intent-ticket',
    'mobileno',
    'valid',
    'intent-value',
    'otpSent',
  ],
  rvp_flag: ['complaint-raise-wrong-picked', 'complaint-raise-not-picked'],
};
