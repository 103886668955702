const { getAccessToken } = require('./keycloak');
const { config } = require('../config/config');
const rvpFlag = config.rvp_flag;

export async function fetchHeaders(payload) {
  try {
    const token = await getAccessToken();
    let bodyObj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        project: 'chatBot',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };
    return bodyObj;
  } catch (error) {
    return {};
  }
}

export async function createOTPPayload(otp, mobileNo) {
  let payload = {
    inputText: mobileNo,
    languageCode: 'en',
    sessionId: 12334,
    intent: {
      entities: [
        {
          name: 'shipment_mobile',
          value: mobileNo,
          name_extra: '',
          value_extra: '',
        },
      ],
      name: 'fetch-otp',
      mobileNumber: mobileNo,
      otp: otp,
    },
    payload: {
      contexts: [],
      params: null,
      loadOrder: null,
      slots: {},
    },
    user: {
      authKey: '111111',
    },
  };
  return payload;
}

export async function createStatusPayload(inputValue, intentName) {
  let flag = false;
  if (rvpFlag.includes(intentName)) flag = true;

  let payload = {
    inputText: inputValue,
    languageCode: 'en',
    sessionId: 12334,
    intent: {
      entities: [
        {
          name: 'shipment_mobile',
          value: inputValue,
          name_extra: '',
          value_extra: '',
        },
      ],
      name: intentName,
      rvp_flag: flag,
    },
    payload: {
      contexts: [],
      params: null,
      loadOrder: null,
      slots: {},
    },
    user: {
      authKey: '111111',
    },
  };
  return payload;
}

export async function createIntentPayload(inputValue, typeAPI) {
  let payload = {
    inputText: inputValue,
    languageCode: 'en',
    sessionId: 12334,
    intent: {
      entities: [
        {
          name: 'type',
          value: inputValue,
          name_extra: '',
          value_extra: '',
        },
      ],
      name: typeAPI,
    },
    payload: {
      contexts: [],
      params: null,
      loadOrder: null,
      slots: {},
    },
    user: {
      authKey: '111111',
    },
  };
  return payload;
}

export async function createPayload(typeValue, typeAPI) {
  let payload = {
    inputText: '11',
    languageCode: 'en',
    sessionId: 12334,
    intent: {
      entities: [
        {
          name: 'type',
          value: typeValue,
          name_extra: '',
          value_extra: '',
        },
      ],
      name: typeAPI,
      mobileNumber: typeValue,
    },
    payload: {
      contexts: [],
      params: null,
      loadOrder: null,
      slots: {},
    },
    user: {
      authKey: '111111',
    },
  };
  return payload;
}

export async function createTicketPayload(inputValue, ticket, name, value) {
  let payload = {
    inputText: inputValue,
    languageCode: 'en',
    sessionId: 12334,
    intent: {
      entities: [
        {
          name: 'shipment_mobile',
          value: inputValue,
          name_extra: name,
          value_extra: value,
        },
      ],
      name: ticket,
    },
    payload: {
      params: null,
      contexts: [
        {
          name: 'ask-help',
          parameters: {
            qtype: '',
          },
          lifespan: 1,
        },
      ],
      loadOrder: null,
      slots: [],
    },
    user: {
      authKey: '111111',
    },
  };
  return payload;
}

export async function createRequestPayload(
  mobileNo,
  operation,
  requestTicket,
  shippingId,
  requestButton,
  updatedNumber,
  futureDate,
  requestType
) {
  try {
    let payload = {
      inputText: '',
      languageCode: 'en',
      sessionId: 12334,
      intent: {
        entities: [
          {
            name: 'shipment_mobile',
            value: '',
            name_extra: '',
            value_extra: '',
          },
        ],
        name: 'requests-insertion',
        mobileNo: mobileNo,
        operation: operation,
        requestType: requestType, // Ticket type status, update, complaint
        requestTicket: requestTicket,
        shippingId: shippingId,
        requestButton: requestButton,
        updatedNumber: updatedNumber,
        futureDate: futureDate,
      },
      payload: {
        params: null,
        contexts: [
          {
            name: 'ask-help',
            parameters: {
              qtype: '',
            },
            lifespan: 1,
          },
        ],
        loadOrder: null,
        slots: [],
      },
      user: {
        authKey: '111111',
      },
    };
    return payload;
  } catch (error) {
    console.log('error in createRequestPayload', error);
    return;
  }
}
